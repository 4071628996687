const url = 'https://aromi.mx'
const siteURL =
  process.env.NODE_ENV == 'production' ? url : 'http://localhost:3000'

const config = {
  site: {
    name: 'Aromi - velas artesanales',
    url: siteURL,
    title: '',
    logo: '/images/logo.png',
    imagePreview: '/images/preview.png',
    description: 'velas',
    creator: '@iver',
    author: 'Iván Jaimes'
  },
  contact: {
    endpoint: 'https://formspree.io/',
    email: 'dmVsYXNAYXJvbWkubXg=',
    cc: 'aXZhbkBhcm9taS5teA==',
    subject: 'W0Fyb21pXSA=',
    phonelink: 'tel:+5574880419',
    phonemask: '+52-5574-88-0419',
    formcode: 'meoqgeqd'
  },
  facebook: 'ivan.iver',
  whatsapp: {
    phone: '5574880419',
    phoneMask: '(55) 7488 0419',
    message: '[Aromi] Deseo realizar el siguiente pedido: ',
    scheme: 'https://'
  },
  social: {
    twitter: 'https://twitter.com/@iver14'
  },
  google: {
    adsense: '',
    trackGA: '',
    trackGTM: '',
    trackAW: '',
    conversionID: '',
    conversionMail: ''
  }
}

export default config

import React from 'react'

interface IProps {
  title?: string
  message?: string
  className?: string
}

const ErrorMessage = (props: IProps) => {
  const { title, message, className } = props

  return (
    <p className={`mt-2 text-xs text-red-600 dark:text-red-400 ${className}`}>
      <span className="font-semibold text-sm">{title}</span> {message}
    </p>
  )
}

export default ErrorMessage

import React from 'react'

interface IProps {
  text: string
  className?: string
  level: number
}

const Title = ({ text, className, level }: IProps) => {
  return (
    <div className={`${className}`}>
      {level == 1 && (
        <h1 className="p-4 bg-gradient-to-br from-violet-400 via-violet-600 to-blue-400 bg-clip-text text-transparent capitalize text-3xl text-center lg:text-left md:text-4xl lg:text-5xl lg:leading-[3.5rem] font-bold ">
          {text}
        </h1>
      )}
      {level == 2 && (
        <h2 className="p-4 bg-gradient-to-br from-violet-400 via-violet-600 to-blue-400 bg-clip-text text-transparent capitalize text-2xl lg:text-4xl font-bold">
          {text}
        </h2>
      )}
    </div>
  )
}

export default Title

import React from 'react'
import Image from 'next/image'

import { ICandlePreview } from './types'

interface IProps {
  candleInfo: ICandlePreview
}

const PreviewItem = (props: IProps) => {
  const { candleInfo } = props
  const image = candleInfo.src ? candleInfo.src : '/images/bg_aromi.webp'
  return (
    <div className="flex flex-col p-2 bg-primary-100 rounded-xl transition-shadow hover:shadow-primary-400 hover:shadow-xl">
      <Image
        width={323}
        height={210}
        className="rounded-t-lg m-0 w-auto aspect-auto"
        src={image}
        alt={candleInfo.title}
        title={candleInfo.title}
      />
      <div className="flex flex-col text-middle">
        <h2 className="relative inline-block align-middle min-h-16 tracking-tight mx-auto content-center text-subtitle">
          {candleInfo.title}
        </h2>
        <div className="relative px-4 pb-6">{candleInfo.benefits}</div>
      </div>
    </div>
  )
}

export default PreviewItem

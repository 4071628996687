import React from 'react'

import { getIntentionCandles } from '@/lib/candles'
import { ICandlePreview } from './types'
import PreviewItem from './PreviewItem'

const CandleList = () => {
  const candles = getIntentionCandles()

  const slideItems = candles.map((candle: ICandlePreview, index: number) => {
    return <PreviewItem key={index} candleInfo={candle} />
  })

  return (
    <section
      id="list"
      className="-top-1.5 w-5/6 md:w-full mx-auto lg:px-10 py-4 mb-0"
    >
      <div className="grid h-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 2xl:gap-14">
        {slideItems}
      </div>
    </section>
  )
}

export default CandleList

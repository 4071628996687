const IntentionCandles = [
  {
    title: 'Amor',
    goal: 'Atrae el amor',
    benefits: `
            Fortalece el vínculo amoroso.
            La combinación de sus ingredientes armoniza mental,
            espiritual y emocionalmente.
            `,
    components: ['Cuarzo rosa'],
    esencia: 'rosas',
    src: '/images/01_amor.jpg',
    category: 'intention',
    price: '359'
  },
  {
    title: 'Abundancia',
    goal: 'Atrae el desarrollo',
    benefits: `
            Potencia el crecimiento profesional, riqueza material
            y espiritual.
            `,
    components: ['Citrino', 'Polvo de pirita'],
    esencia: 'coco con canela',
    src: '/images/03_abundancia.jpg',
    category: 'intention',
    price: '359'
  },
  {
    title: 'Salud',
    goal: 'Bienestar de tu salud',
    benefits: `
            Favorece la sanación física
            Eleva el ánimo y mejora el sistema inmunológico.
            `,
    components: ['Cuarzo blanco'],
    esencia: 'Bergamota',
    src: '/images/04_salud.jpg',
    category: 'intention',
    price: '359'
  },
  {
    title: 'Concentración',
    goal: 'Mejorar el enfoque',
    benefits: `
            Favorece la concentración y el estado de alerta.
            Mejora el enfoque ayudando a la fatiga mental y física.
            Alivia el dolor de cabeza y migraña.
            `,
    components: ['Amatista'],
    esencia: 'Menta',
    src: '/images/05_concentracion.jpg',
    category: 'intention',
    price: '359'
  },
  {
    title: 'Paz Mental',
    goal: 'Tranquilidad',
    benefits: `
            Promueve la tranquilidad y libera del estrés
            `,
    components: ['Cuarzo blanco', 'Amatista'],
    esencia: 'Bosque',
    src: '/images/02_paz.jpg',
    category: 'intention',
    price: '359'
  }
  /* {
    title: 'Protección',
    goal: 'Vitalizar',
    benefits: `
            Elimina bloqueos energéticos.
            Absorbe energías no positivas del entorno,
            vitaliza el alma y elimina bloqueos energéticos.
            `,
    components: ['Obsidiana'],
    esencia: 'Mango',
    src: '/images/intencion.jpg',
    category: 'intention',
    price: '359'
  },
  {
    title: 'Abre Caminos',
    goal: 'Seguridad',
    benefits: `
            Ayuda a las transiciones laborales y nuevos retos.
            Contribuye a la seguridad de uno mismo.
            `,
    components: ['Citrino', 'Pirita'],
    esencia: 'Romero',
    src: '/images/intencion.jpg',
    category: 'intention',
    price: '359'
  } */
]

export const Aromatherapy = [
  {
    description: `
    Alivia el estrés.
    Restablece el apetito.
    Disminuye ansiedad.
    `,
    esencia: 'Bergamota',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  },
  {
    description: 'Calma el sistema nervioso y las alergias.',
    esencia: 'Ciprés',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  },
  {
    description: 'Alivia dolores de cabeza por tensión y estrés.',
    esencia: 'Lavanda',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  },
  {
    description: 'Reduce cansancio mental y alivia el estrés.',
    esencia: 'Limón',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  },
  {
    description: 'Alivia dolores musculares.',
    esencia: 'Romero',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  },
  {
    description: 'Reduce ataques de pánico y depresión.',
    esencia: 'Ylang-Ylang',
    src: '/images/amor.jpg',
    category: 'aromatherapy',
    price: '359'
  }
]

export const Moldes = [
  { title: '', description: '', src: '/images/amor.jpg' },
  { title: '', description: '', src: '/images/amor.jpg' },
  { title: '', description: '', src: '/images/amor.jpg' },
  { title: '', description: '', src: '/images/amor.jpg' }
]

export default IntentionCandles
